import React from 'react';
import styled from 'styled-components';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Italic = styled.em``;
const Bold = styled.strong``;
const Text = styled.p``;
const Underline = styled.span`
  text-decoration: underline;
`;

const markOptions = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
    [MARKS.UNDERLINE]: text => <Underline>{text}</Underline>,
  },
};

const options = {
  ...markOptions,
  renderNode: {
    [BLOCKS.PARAGRAPH]: children => <Text>{documentToReactComponents(children, markOptions)}</Text>,
  },
};

export default function RichText({ children }) {
  return documentToReactComponents(children, options);
}
