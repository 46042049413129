import React from 'react';
import styled from 'styled-components';
import { Wrap, Sidebar, Content } from '@components/content';
import { Grey, Spacer, Col, Row } from '@components/utils';
import RichText from '@components/rich-text';
import Container from '@components/container';
import CopyLink from '@components/copy-link';

export default ({ services, links, about }) => {
  return (
    <About>
      <Spacer size={19.5} md={15.5} sm={13.5} />
      <Container>
        <Wrap>
          <Sidebar>
            <img src={about.portrait} width="318" alt="avatar" className="avatar" />
            <Spacer size={3} />
          </Sidebar>
          <Content>
            <Row>
              <Col sm={12} md={12} lg={7}>
                <h2>Hello</h2>
                <Spacer size={3} />
                <Grey>
                  <RichText>{about.bio}</RichText>
                </Grey>
                <Spacer size={3} />
                <h2>Recent Partners</h2>
                <Spacer size={3} />
                <Grey>
                  <p>{about.recentPartners}</p>
                </Grey>
              </Col>
              <Col sm={12} md={12} lg={5}>
                <Spacer md={3} />
                <h2>For Hire</h2>
                <Spacer size={3} />
                <Grey>
                  {services.map((item, index) => (
                    <div key={index}>{item}</div>
                  ))}
                </Grey>

                <Spacer size={3} />
                <h2>Contact</h2>
                <Spacer size={3} />
                <Grey>
                  <div>
                    <CopyLink link={about.email} isEmail />
                  </div>
                  <div>{about.phone}</div>
                </Grey>

                <Spacer size={3} />
                <h2>Social</h2>
                <Spacer size={3} />
                <Grey>
                  {links.map((link, index) => (
                    <div key={index}>
                      <a href={link.url}>{link.name}</a>
                    </div>
                  ))}
                </Grey>
              </Col>
            </Row>
          </Content>
        </Wrap>
      </Container>
      <Spacer size={13.5} md={9.5} sm={7.5} />
    </About>
  );
};

const About = styled.main`
  flex: 1;

  h2 {
    font-size: 16px;
    margin: 0;
  }

  @media (max-width: 600px) {
    .avatar {
      width: 100%;
    }
  }
`;
